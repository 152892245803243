import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { scaleTable } from 'utils';
import Banner from './components/Banner';
import FillInfo from './components/FillInfo';
import LeafNumber from './components/LeafNumber';
import NavBar from './components/NavBar';
import TopHeader from './components/TopHeader';
// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height,
//   };
// }
const Home = () => {
  const { leafNumber } = useSelector(
    (state: RootState) => state.leafNumberReducer
  );

  // const [windowDimensions, setWindowDimensions] = useState(
  //   getWindowDimensions()
  // );
  const scaleValue = scaleTable({
    currentScreen: { width: window.innerWidth, height: window.innerHeight },
    desire: 0,
  });
  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  return (
    <div
      style={{
        maxHeight: '150vh',
        overflowY: 'hidden',
      }}
      className="position-relative mb-5 pb-3"
    >
      <TopHeader />
      {/* <NavBar /> */}

      <div style={{}} className={'d-flex flex-column justify-content-center'}>
        {!leafNumber ? (
          <>
            <Banner />
            <FillInfo currentWidth={window.innerWidth} />
          </>
        ) : (
          <LeafNumber scaleValue={scaleValue} />
        )}
      </div>

      <img
        src={require('assets/images/body_left.jpeg')}
        className="position-absolute"
        style={{
          width: scaleValue * 300,
          height: scaleValue * 300,
          bottom: 0,
          zIndex: -2,
          left: 0,
          opacity: 0.2,
        }}
        alt={'left'}
      />
      <img
        src={require('assets/images/body_right.jpeg')}
        className="position-absolute"
        style={{
          width: scaleValue * 300,
          height: scaleValue * 300,
          bottom: 0,
          zIndex: -2,
          right: 0,
          opacity: 0.2,
        }}
        alt={'right'}
      />
    </div>
  );
};

export default Home;
