import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';

import type { RootState } from 'store';
import YinYang, { FiveElements, ThienBan, Tinh } from 'model';
import useOnScreen, {
  handleResidenceInThePalace,
  positionMenhTaiQuanDi,
  scaleTable,
  useDidUpdateEffect,
} from 'utils';
import { colorFiveElements, zodiac } from 'constant';
import { convertSolar2Lunar } from 'utils/convertSolarLunar';
import { CanvasElementContainer } from 'html2canvas/dist/types/dom/replaced-elements/canvas-element-container';
import { updateInfo, updateLeafNumberInfo } from 'services/slices/leafNumber';
import * as htmlToImage from 'html-to-image';
import { requestComment } from 'api';

interface PhuTinh {
  ten: string;
  acronym: string;
  level: number;
  color: string;
  fontWeight: string;
}

const LeafNumber = ({ scaleValue }: { scaleValue: number }) => {
  const dispatch = useDispatch();
  const { leafNumber, info } = useSelector(
    (state: RootState) => state.leafNumberReducer
  );

  const ref = createRef<HTMLTableElement>();
  // const refCanvasTest = createRef<any>();
  const refTable = createRef<HTMLTableElement>();
  const refCanvas = useRef<any>();
  let ctxCanvas: any = null;
  const isVisibleTable = useOnScreen(refTable);
  // const isVisibleCanvasTest = useOnScreen(refCanvasTest);
  const [image, setImage] = useState('');
  const [isNone, setIsNone] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [phone, setPhone] = useState('');
  const [notes, setNotes] = useState('');

  const trietTuan = handleTrietTuan({
    triet: leafNumber?.triet ?? [],
    tuan: leafNumber?.tuan ?? [],
  });

  useEffect(() => {
    if (!refCanvas?.current) return;
    const canvasEle = refCanvas.current;
    ctxCanvas = canvasEle.getContext('2d');
    ctxCanvas.clearRect(0, 0, canvasEle.width, canvasEle.height);
  }, [refCanvas?.current, leafNumber]);

  const handleRequestComment = async () => {
    if (phone.trim() === '') {
      return alert('Số điện thoại không được để trống!');
    } else if (!/0[1-9][0-9]{8}/.test(phone)) {
      return alert('Vui lòng nhập đúng định dạng số điện thoại!');
    }
    try {
      const response = await requestComment({
        name: info?.ten,
        phone: phone,
        notes: notes,
        infos: {
          gioiTinh: info?.gioiTinh,
          ngaySinh: info?.ngaySinh,
          thangSinh: info?.thangSinh,
          namSinh: info?.namSinh,
          gioSinh: info?.gioSinh,
          phutSinh: info?.phutSinh,
          lichAmDuong: info?.lichAmDuong,
          thangLap: info?.thangLap,
          namXem: info?.namXem,
        },
      });
      if (!!response.data) {
        setOpenModal(false);
        alert('Gửi yêu cầu bình giải thành công!');
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  function positionTrietTuan(item: number) {
    const defaultTrietTuan = {
      bottom: -10,
    };

    switch (item) {
      // left
      case 0:
      case 1:
      case 11:
        return {
          left: -25,
          top: -10,
        };
      // top
      case 2:
      case 3:
      case 4:
        return {
          top: -10,
        };
      // right
      case 5:
      case 6:
      case 7:
        return {
          right: -25,
          bottom: -10,
        };

      // bottom
      default:
        return defaultTrietTuan;
    }
  }

  function handleTrietTuan({
    triet,
    tuan,
  }: {
    triet: number[];
    tuan: number[];
  }) {
    if (!_.isEmpty(triet) && _.isEmpty(tuan)) return;
    const tmpTriet = _.cloneDeep(triet);
    const tmpTuan = _.cloneDeep(tuan);

    const sortTriet: number[] = tmpTriet.sort();
    const sortTuan: number[] = tmpTuan.sort();
    const positionTriet = positionTrietTuan(sortTriet[0] ?? -1);
    const positionTuan = positionTrietTuan(sortTuan[0] ?? -1);

    return {
      positionTriet,
      positionTuan,
      relativeTriet: sortTriet[0] ?? -1,
      relativeTuan: sortTuan[0] ?? -1,
    };
  }

  const renderTrietTuan = (label: string, style: any) => {
    let tmpStyle = style;
    if (label.length > 6) {
      if (!!tmpStyle.left) {
        tmpStyle.left = -40;
      } else if (!!tmpStyle.right) {
        tmpStyle.right = -40;
      }
    }
    return (
      <div
        style={{
          ...tmpStyle,
        }}
        className={`position-absolute text-white px-1 text-center ${
          label.length > 6 ? 'tuan-and-triet' : 'tuan-or-triet'
        }`}
      >
        {label}
      </div>
    );
  };

  const itemLeafNumber = ({
    position,
    data,
  }: {
    position: number;
    data: ThienBan;
  }) => {
    let radiusStyle: any = {
      borderWidth: 1,
      borderColor: '#000',
      borderStyle: 'solid',
    };
    switch (position) {
      case 0:
      case 1:
        radiusStyle = {
          ...radiusStyle,
          borderBottomWidth: 2,
        };
        break;
      case 3:
      case 4:
        radiusStyle = {
          ...radiusStyle,
          borderLeftWidth: 2,
        };
        break;
      case 6:
      case 7:
        radiusStyle = {
          ...radiusStyle,
          borderTopWidth: 2,
        };
        break;
      case 9:
      case 10:
        radiusStyle = {
          ...radiusStyle,
          borderRightWidth: 2,
        };
        break;
      case 2:
        radiusStyle = {
          ...radiusStyle,
          borderBottomLeftRadius: 6,
          borderBottomWidth: 2,
          borderLeftWidth: 2,
        };
        break;
      case 5:
        radiusStyle = {
          ...radiusStyle,
          borderTopLeftRadius: 6,
          borderTopWidth: 2,
          borderLeftWidth: 2,
        };
        break;
      case 8:
        radiusStyle = {
          ...radiusStyle,
          borderTopRightRadius: 6,
          borderTopWidth: 2,
          borderRightWidth: 2,
        };
        break;
      case 11:
        radiusStyle = {
          ...radiusStyle,
          borderBottomRightRadius: 6,
          borderBottomWidth: 2,
          borderRightWidth: 2,
        };
        break;
    }
    let cung = _.cloneDeep(data.cung ?? '');
    let than = '';
    if (data.cung.toUpperCase().includes('<Thân>'.toUpperCase())) {
      cung = cung.toUpperCase().replace('<Thân>'.toUpperCase(), '');
      than = 'Thân';
    }
    const tmp = data.canChiCung.split(' ');
    let canChiCung = <span></span>;
    if (tmp.length > 1) {
      const dc = zodiac.find(
        (v) => v.label.toLowerCase() === tmp[1].toLowerCase()
      );
      const colorDC = !!dc ? dc.color : '#000';
      canChiCung = (
        <span
          style={{
            width: '15%',
            lineHeight: '14px',
          }}
        >
          <span
            style={{
              fontWeight: '700',
              color: '#000',
              fontSize: 11,
              lineHeight: '14px',
            }}
            className="text-default"
          >
            {tmp[0].slice(0, 1)}.
          </span>
          <span
            style={{
              fontWeight: '700',
              color: colorDC,
              fontSize: 11,
              lineHeight: '14px',
            }}
            className="text-default"
          >
            {tmp[1]}
          </span>
        </span>
      );
    }
    const otherPhuTinhXau = data.phuTinh
      .filter((item) => item.totXau === 1)
      .slice(9);
    const phuTinhTot: PhuTinh[] = data.phuTinh
      .filter((item) => item.totXau === 0)
      .slice(0, 9)
      .concat(otherPhuTinhXau)
      .map((item, __) => {
        let color = YinYang.fiveElements.Water.color;
        for (const key in YinYang.fiveElements) {
          if (
            YinYang.fiveElements[
              key as keyof FiveElements
            ].name.toUpperCase() === item.nguHanh.toUpperCase()
          ) {
            color = YinYang.fiveElements[key as keyof FiveElements].color;
          }
        }
        const residenceInThePalace = handleResidenceInThePalace(item, position);
        return {
          ten: item?.ten,
          level: residenceInThePalace?.level ?? 1,
          fontWeight: residenceInThePalace?.fontWeight ?? '400',
          acronym: residenceInThePalace?.acronym ?? '',
          color: color,
        };
      })
      .sort((x, y) => x.level - y.level);
    const otherPhuTinhTot = data.phuTinh
      .filter((item) => item.totXau === 0)
      .slice(9);
    const phuTinhXau: PhuTinh[] = data.phuTinh
      .filter((item) => item.totXau === 1)
      .slice(0, 9)
      .concat(otherPhuTinhTot)
      .map((item, __) => {
        let color = YinYang.fiveElements.Water.color;
        for (const key in YinYang.fiveElements) {
          if (
            YinYang.fiveElements[
              key as keyof FiveElements
            ].name.toUpperCase() === item.nguHanh.toUpperCase()
          ) {
            color = YinYang.fiveElements[key as keyof FiveElements].color;
          }
        }
        const residenceInThePalace = handleResidenceInThePalace(item, position);
        return {
          ten: item?.ten,
          level: residenceInThePalace?.level ?? 1,
          fontWeight: residenceInThePalace?.fontWeight ?? '400',
          acronym: residenceInThePalace?.acronym ?? '',
          color: color,
        };
      })
      .sort((x, y) => x.level - y.level);

    return (
      <div
        style={{
          ...radiusStyle,
          width: 170,
        }}
        className="d-flex flex-column position-relative align-items-center justify-content-between td-leafnumber px-1 py-2"
      >
        {position === trietTuan?.relativeTriet &&
          trietTuan?.relativeTriet !== trietTuan?.relativeTuan &&
          renderTrietTuan('Triệt', trietTuan.positionTriet)}
        {position === trietTuan?.relativeTuan &&
          trietTuan?.relativeTriet !== trietTuan?.relativeTuan &&
          renderTrietTuan('Tuần', trietTuan.positionTuan)}
        {position === trietTuan?.relativeTuan &&
          trietTuan?.relativeTriet === trietTuan?.relativeTuan &&
          renderTrietTuan('Tuần-Triệt', trietTuan.positionTriet)}
        <div className="w-100">
          <div
            style={{ fontWeight: '700' }}
            className="d-flex justify-content-between"
          >
            {canChiCung}
            <div
              className="text-center text-uppercase"
              style={{
                width: '70%',
                fontWeight: '800',
                fontSize: 13,
                fontFamily: 'Roboto Condensed',
                color: '#03036E',
                whiteSpace: 'nowrap',
                paddingLeft: cung.length >= 8 && !!than ? 12 : 0,
              }}
            >
              {cung}
              {!!than ? (
                <span
                  style={{
                    color: colorFiveElements.hoa,
                    fontSize: 13,
                  }}
                >
                  {' '}
                  {than}
                </span>
              ) : (
                ''
              )}
            </div>
            <div
              style={{
                fontWeight: '700',
                fontSize: 12,
                color: 'black',
                width: '15%',
                whiteSpace: 'nowrap',
                lineHeight: '12px',
                marginTop: 2.5,
              }}
              className="text-default text-end"
            >
              {data.daiVan}
            </div>
          </div>
        </div>
        <div
          className="mt-1 mb-2 d-flex flex-column justify-content-center"
          style={{
            height: '15%',
          }}
        >
          {data.chinhTinh.map((item: Tinh, index: number) => {
            let color = YinYang.fiveElements.Water.color;
            for (const key in YinYang.fiveElements) {
              if (
                YinYang.fiveElements[
                  key as keyof FiveElements
                ].name.toUpperCase() === item.nguHanh.toUpperCase()
              ) {
                color = YinYang.fiveElements[key as keyof FiveElements].color;
              }
            }

            const residenceInThePalace = handleResidenceInThePalace(
              item,
              position
            );

            return (
              <div
                key={index}
                className="text-center chinh-tinh mb-0 text-uppercase"
                style={{
                  color: color,
                }}
              >
                {item.ten}{' '}
                {!!residenceInThePalace?.acronym
                  ? `(${residenceInThePalace?.acronym})`
                  : ''}
              </div>
            );
          })}
        </div>

        <div
          className="w-100"
          style={{
            // height: '60%',
            flexGrow: 1,
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="w-50 d-flex flex-column align-items-start">
              {phuTinhTot.map((item: PhuTinh, index) => {
                return (
                  <div
                    style={{
                      lineHeight: 1.2,
                      color: item.color,
                      fontWeight: `${item.fontWeight}`,
                      whiteSpace: 'nowrap',
                      fontSize: item.fontWeight === '900' ? 13.5 : 12.5,
                    }}
                    key={index}
                    className="text-break phu-tinh"
                  >
                    {item.ten}
                    {!!item.acronym ? `(${item.acronym})` : ''}
                  </div>
                );
              })}
            </div>
            <div className="w-50 d-flex flex-column align-items-start">
              {phuTinhXau.map((item: PhuTinh, index) => {
                return (
                  <div
                    style={{
                      lineHeight: 1.2,
                      color: item.color,
                      fontWeight: `${item.fontWeight}`,
                      whiteSpace: 'nowrap',
                      fontSize: item.fontWeight === '900' ? 13.5 : 12.5,
                    }}
                    key={index}
                    className="text-break phu-tinh"
                  >
                    {item.ten}
                    {!!item.acronym ? `(${item.acronym})` : ''}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-100 mb-1">
          <div className="d-flex justify-content-between align-items-center">
            <div
              style={{ width: '30%', fontSize: 12 }}
              className="text-default"
            >
              {data.tieuHan}
            </div>
            <div
              className="text-default text-center flex-grow-1"
              style={{ fontWeight: '700', fontSize: 12, whiteSpace: 'nowrap' }}
            >
              {data.saoTrangSinh}
            </div>
            <div
              style={{ width: '30%', whiteSpace: 'nowrap', fontSize: 12 }}
              className="text-default text-end"
            >
              {data.nguyetHan}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderZodiac = () => {
    return zodiac.map((item, index) => {
      const style: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
      } = {};

      switch (index) {
        case 11:
        case 0:
        case 1:
        case 2:
          style.top = 0;
          break;
        case 3:
        case 4:
          style.right = 0;
          break;
        case 9:
        case 10:
          style.left = 0;
          break;
        case 5:
        case 6:
        case 7:
        case 8:
          style.bottom = 0;
          break;
      }

      switch (index) {
        case 11:
        case 8:
          style.left = 0;
          break;
        case 0:
        case 7:
          style.left = 93.5;
          break;
        case 1:
        case 6:
          style.right = 93.5;
          break;
        case 2:
        case 5:
          style.right = 0;
          break;
        case 3:
        case 10:
          style.top = 125;
          break;
        case 4:
        case 9:
          style.bottom = 125;
          break;
      }

      return (
        <span
          style={{ ...style, fontSize: 10, fontWeight: '500' }}
          key={index}
          className="position-absolute p-1"
        >
          {item.label}
        </span>
      );
    });
  };

  useEffect(() => {
    try {
      const taiQuanDi = ['TÀI BẠCH', 'QUAN LỘC', 'THIÊN DI'];
      let positionMenh = { x: 0, y: 0 };
      leafNumber?.thienBan.every((item) => {
        if (item.cung.toUpperCase().includes('MỆNH')) {
          positionMenh = positionMenhTaiQuanDi(item.vitri);
          return false;
        }
        return true;
      });
      leafNumber?.thienBan.forEach((item) => {
        taiQuanDi.every((v) => {
          if (item.cung.toUpperCase().includes(v)) {
            const positionTaiQuanDi = positionMenhTaiQuanDi(item.vitri);
            drawLine({
              x: positionMenh.x,
              y: positionMenh.y,
              x1: positionTaiQuanDi.x,
              y1: positionTaiQuanDi.y,
            });
            return false;
          }
          return true;
        });
      });
    } catch (error) {}
  }, [isVisibleTable, ctxCanvas]);

  // draw a line
  const drawLine = (info: any) => {
    const { x, y, x1, y1 } = info;
    if (!ctxCanvas) return;
    ctxCanvas.beginPath();
    ctxCanvas.moveTo(x, y);
    ctxCanvas.lineTo(x1, y1);
    ctxCanvas.strokeStyle = '#bbb5b5';
    ctxCanvas.lineWidth = 0.8;
    ctxCanvas.stroke();
  };

  const renderMenhTaiQuan = () => {
    return (
      <canvas
        id="line"
        width="337px"
        height="478px"
        ref={refCanvas}
        style={{ position: 'absolute', zIndex: 0 }}
      ></canvas>
    );
  };

  const renderCenter = () => {
    const w30 = '30%';
    const w35 = '35%';
    const lunarNamSinh = convertSolar2Lunar(
      Number(info?.ngaySinh ?? 0),
      Number(info?.thangSinh ?? 0),
      Number(info?.namSinh ?? 1900)
    );

    // const lunarNamXem = convertSolar2Lunar(
    //   new Date().getDate(),
    //   new Date().getMonth(),
    //   Number(info?.namXem ?? 1900)
    // );
    const age = Number(info?.namXem ?? 1900) - lunarNamSinh[2] + 1;

    return (
      <div
        style={{
          height: 481.5,
          backgroundImage: `url(${require('../../../assets/images/canh_dao.png')})`,
        }}
        className="d-flex flex-column border-center position-relative"
      >
        {/* {renderZodiac()} */}
        {renderMenhTaiQuan()}
        {/* <img
          alt="..."
          className="img-leaf-number-center"
          src={require('assets/images/leaf_number_center.png')}
        /> */}
        <img
          width={40}
          alt="..."
          className="img-leaf-number-text"
          src={require('assets/images/leaf_number_text.png')}
        />

        {/* <img
          alt="..."
          className="canh-dao"
          src={require('assets/images/canh_dao.png')}
        /> */}

        {/* <img
          width={40}
          alt="..."
          className="img-leaf-number-dau"
          src={require('assets/images/dau.png')}
        /> */}
        <div
          style={{ width: 340, left: -1 }}
          className="d-flex flex-column border-center position-absolute"
        >
          <div className="d-flex justify-content-center mt-4">
            <span className="w-75 title-leaf-number-center text-center py-1">
              HỌC VIỆN TỬ VI VIỆT NAM
            </span>
          </div>
          {/* <div className="d-flex justify-content-center mb-3">
          <span className="sub-title-leaf-number-center px-3">
            Hocvientuvi.com
          </span>
        </div> */}

          <div
            style={{
              marginTop: 13,
            }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div className="w-75">
              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Họ tên:{' '}
                </div>

                <div className="value">{info?.ten}</div>
              </div>

              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Năm:{' '}
                </div>
                <div style={{ width: w35 }} className="value">
                  {leafNumber?.diaBan.lich.namDuong} (
                  {leafNumber?.diaBan.lich.namAm})
                </div>
                <div className="value">{leafNumber?.diaBan.lich.canChiNam}</div>
              </div>

              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Tháng:{' '}
                </div>
                <div style={{ width: w35 }} className="value">
                  {leafNumber?.diaBan.lich.thangDuong} (
                  {leafNumber?.diaBan.lich.thangAm})
                </div>
                <div className="value">
                  {leafNumber?.diaBan.lich.canChiThang}
                </div>
              </div>

              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Ngày:{' '}
                </div>
                <div style={{ width: w35 }} className="value">
                  {leafNumber?.diaBan.lich.ngayDuong} (
                  {leafNumber?.diaBan.lich.ngayAm})
                </div>

                <div className="value">
                  {leafNumber?.diaBan.lich.canChiNgay}
                </div>
              </div>

              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Giờ:{' '}
                </div>

                <div
                  style={{ width: w35 }}
                  className="value"
                >{`${info?.gioSinh}h : ${info?.phutSinh}p`}</div>

                <div className="value">{leafNumber?.diaBan.lich.canChiGio}</div>
              </div>

              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Năm xem:{' '}
                </div>

                <div style={{ width: w35 }} className="value">
                  {info?.namXem}
                </div>

                <div className="value">
                  {leafNumber?.diaBan.namXem.canChiNam}
                  <br />
                  {age <= 0 ? 0 : age} Tuổi
                </div>
              </div>

              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Âm dương:{' '}
                </div>

                <div className="value">
                  {leafNumber?.diaBan.amDuong.amDuong}
                  <br />
                  Âm dương {leafNumber?.diaBan.amDuong.kieuAmDuong}
                </div>
              </div>

              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Mệnh:{' '}
                </div>

                <div className="value">{leafNumber?.diaBan.banMenh}</div>
              </div>

              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Cục:
                </div>
                <div className="value">{leafNumber?.diaBan.cucBanMenh}</div>
              </div>
              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Chủ mệnh:
                </div>

                <div className="value">{leafNumber?.diaBan.chuMenh}</div>
              </div>
              <div className="d-flex">
                <div style={{ width: w30 }} className="label">
                  Chủ thân:
                </div>

                <div className="value">{leafNumber?.diaBan.chuThan}</div>
              </div>
            </div>
          </div>
          <div
            className="align-self-center"
            style={{
              fontSize: 15,
              fontWeight: '900',
              color: colorFiveElements.hoa,
              fontFamily: 'Roboto Condensed',
              width: '90%',
              paddingLeft: 25,
              marginTop: 35,
            }}
          >
            <div className="d-flex mt-1 justify-content-between">
              <div className="">
                KHAI GIẢNG LỚP TỬ VI: HỌC ONLINE
                <br />
                TẢI App IOS/Android: Học Viện Tử Vi
                <br />
              </div>
              <img
                style={{ marginTop: 5, marginRight: 5, marginBottom: 2.5 }}
                width={'37px'}
                height={'37px'}
                alt="..."
                src={require('assets/images/logo.png')}
              />
            </div>
            <span
              style={{
                fontSize: 15.5,
                whiteSpace: 'nowrap',
                fontWeight: '600',
              }}
            >
              Nếu chưa biết gì càng tốt: Hocvientuvi.com
            </span>
          </div>
        </div>
      </div>
    );
  };

  const downloadImage = (blob: any, fileName: string) => {
    const fakeLink: any = window.document.createElement('a');
    fakeLink.style = 'display:none;';
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  const exportAsImage = async (element: any, imageFileName: string) => {
    downloadImage(image, imageFileName);

    // const canvasElement: any = document.getElementById('canvas-leafnumber');
    // if (!canvasElement) return;
    // const blob = canvasElement.toDataURL('image/png', 1.0);
    // downloadImage(blob, imageFileName);
  };

  useEffect(() => {
    if (!ref?.current) return;
    handleGetImage(ref.current);
  }, [isVisibleTable]);

  const handleGetImage = async (element: any) => {
    const delayDebounceFn = setTimeout(async () => {
      if (isNone) return;
      const canvas = await html2canvas(element);
      const blob = canvas.toDataURL('image/jpeg', 1.0);
      setImage(blob);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setIsNone(true);
    }, 1000);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [image]);

  // useEffect(() => {
  //   handleHtmlToCanvas();
  // }, [isVisibleTable, ctxCanvas]);

  // const handleHtmlToCanvas = () => {
  //   if (!refTable?.current) return;

  //   htmlToImage.toCanvas(refTable.current).then(function (canvas) {
  //     const canvasElement = document.getElementById('canvas-leafnumber');
  //     const parent = document.getElementById('parent-leafnumber');
  //     if (!parent) return;
  //     if (!!canvasElement) {
  //       parent?.removeChild(canvasElement);
  //     }
  //     canvas.id = 'canvas-leafnumber';
  //     canvas.setAttribute(
  //       'style',
  //       `width: ${680 * scaleValue}px; height: ${
  //         963 * scaleValue
  //       }px;transform: translateX(-100%);transform-origin: 0% 0px;position: absolute;`
  //     );

  //     parent.appendChild(canvas);
  //     const blob = canvas.toDataURL('image/jpeg', 1.0);
  //     setImage(blob);
  //   });
  // };

  // useEffect(() => {
  //   try {
  //     if (!refCanvasTest?.current) return;
  //     const canvas = refCanvasTest.current;
  //     const context = canvas.getContext('2d');
  //     let rect = canvas.getBoundingClientRect();
  //     canvas.width = rect.width * window.devicePixelRatio;
  //     canvas.height = rect.height * window.devicePixelRatio;
  //     // ensure all drawing operations are scaled
  //     context.scale(window.devicePixelRatio, window.devicePixelRatio);
  //     canvas.style.width = rect.width + 'px';
  //     canvas.style.height = rect.height + 'px';

  //     // draw the colored region
  //     context.beginPath();
  //     context.font = '14px Roboto Condensed';
  //     context.fillStyle = '#ffad00';
  //     context.textAlign = 'center';
  //     context.fillText('Hello World', canvas.width / 2, canvas.height / 2);
  //     context.stroke();
  //   } catch (error) {
  //     console.log('error: ', error);
  //   }
  // }, [isVisibleCanvasTest]);

  if (_.isEmpty(leafNumber)) return null;

  return (
    <React.Fragment>
      <div className="d-flex justify-content-center mt-1">
        <div
          style={{
            width: 500,
          }}
          className=""
        >
          <span style={{ fontSize: 16, fontWeight: '900', marginRight: 50 }}>
            Lá số tử vi của bạn
          </span>
        </div>
      </div>
      {/* <div>
        <canvas
          ref={refCanvasTest}
          width="550px"
          height="350px"
          id="canvas-test"
        ></canvas>
      </div> */}
      <div
        id="parent-leafnumber"
        style={{ height: scaleValue * 970 }}
        className="d-flex position-relative mt-1"
      >
        <img
          src={image}
          className="position-absolute"
          style={{
            transform: `scale(${scaleValue}) translateX(-50%)`,
            transformOrigin: '0% 0px',
            left: '50%',
            width: 680,
            height: 963,
          }}
          alt={'Đang tải lá số...'}
        />

        <div
          ref={ref}
          className="position-absolute"
          style={{
            transform: `scale(${isMobile ? 1 : 2}) translateX(-100%)`,
            transformOrigin: '0% 0px',
            // left: '50%',
            display: isNone ? 'none' : 'block',
          }}
        >
          <div
            style={{}}
            ref={refTable}
            id="div-table"
            className="p-1 bg-white"
          >
            <table
              id={'table'}
              cellPadding={0}
              cellSpacing={0}
              className="position-relative"
              style={{
                width: 680,
                borderSpacing: 0,
                borderCollapse: 'collapse',
                background: '#fff',
              }}
            >
              <tbody>
                <tr>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 5,
                      data: leafNumber.thienBan[5],
                    })}
                  </td>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 6,
                      data: leafNumber.thienBan[6],
                    })}
                  </td>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 7,
                      data: leafNumber.thienBan[7],
                    })}
                  </td>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 8,
                      data: leafNumber.thienBan[8],
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 4,
                      data: leafNumber.thienBan[4],
                    })}
                  </td>
                  <td className="w-50 td-leafnumber" colSpan={2} rowSpan={2}>
                    {renderCenter()}
                  </td>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 9,
                      data: leafNumber.thienBan[9],
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 3,
                      data: leafNumber.thienBan[3],
                    })}
                  </td>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 10,
                      data: leafNumber.thienBan[10],
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 2,
                      data: leafNumber.thienBan[2],
                    })}
                  </td>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 1,
                      data: leafNumber.thienBan[1],
                    })}
                  </td>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 0,
                      data: leafNumber.thienBan[0],
                    })}
                  </td>
                  <td className="w-25 td-leafnumber">
                    {itemLeafNumber({
                      position: 11,
                      data: leafNumber.thienBan[11],
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
        className={''}
        backdrop={true}
      >
        <ModalHeader toggle={() => setOpenModal(false)}>
          Vui lòng nhập thông tin liên hệ
        </ModalHeader>
        <ModalBody>
          <Input
            className="my-2"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Số điện thoại"
            type="text"
          />
          <Input
            id="notes"
            name="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Nội dung yêu cầu"
            type="textarea"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleRequestComment}>
            Gửi
          </Button>{' '}
          <Button color="secondary" onClick={() => setOpenModal(false)}>
            Huỷ
          </Button>
        </ModalFooter>
      </Modal>
      <div className="d-flex justify-content-center">
        {!_.isEmpty(leafNumber) && (
          <div
            style={{
              width: 400,
            }}
            className="d-flex justify-content-between"
          >
            <Button
              onClick={() => {
                exportAsImage(refTable.current, 'lasotuvi');
              }}
              color="danger"
              className="rounded my-1"
              outline
            >
              Tải lá số
            </Button>{' '}
            <Button
              onClick={() => {
                setOpenModal(true);
              }}
              color="info"
              className="rounded my-1"
              outline
            >
              Gửi yêu cầu bình giải
            </Button>
            <Button
              onClick={() => {
                dispatch(updateLeafNumberInfo(undefined));
                dispatch(updateInfo(undefined));
              }}
              className="rounded my-1"
              outline
            >
              Quay lại
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default LeafNumber;
